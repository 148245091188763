import clsx from "clsx";

type TypographyProps = {
	children?: React.ReactNode;
	className?: string;
	style?: Partial<React.CSSProperties>;
};

export function TypographyH1({ children, className, style }: TypographyProps) {
	return (
		<h1 className={clsx("scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl", className)} style={style}>
			{children}
		</h1>
	);
}

export function TypographyH2({ children, className, style }: TypographyProps) {
	return (
		<h2 className={clsx("scroll-m-20 text-3xl font-semibold tracking-tight first:mt-0", className)} style={style}>
			{children}
		</h2>
	);
}

export function TypographyH3({ children, className, style }: TypographyProps) {
	return (
		<h3 className={clsx("scroll-m-20 text-2xl font-semibold tracking-tight", className)} style={style}>
			{children}
		</h3>
	);
}

export function TypographyH4({ children, className, style }: TypographyProps) {
	return (
		<h4 className={clsx("scroll-m-20 text-xl font-semibold tracking-tight", className)} style={style}>
			{children}
		</h4>
	);
}

export function TypographyH5({ children, className, style }: TypographyProps) {
	return (
		<h4 className={clsx("scroll-m-20 text-lg font-semibold tracking-tight", className)} style={style}>
			{children}
		</h4>
	);
}

export function TypographyP({ children, className, style }: TypographyProps) {
	return (
		<p className={clsx("text-base leading-7", className)} style={style}>
			{children}
		</p>
	);
}

export function TypographyLarge({ children, className, style }: TypographyProps) {
	return (
		<div className={clsx("text-lg font-semibold", className)} style={style}>
			{children}
		</div>
	);
}

export function TypographySmall({ children, className, style }: TypographyProps) {
	return (
		<small className={clsx("text-sm font-medium leading-none", className)} style={style}>
			{children}
		</small>
	);
}

export function TypographyMuted({ children, className, style }: TypographyProps) {
	return (
		<p className={clsx("text-sm text-muted-foreground", className)} style={style}>
			{children}
		</p>
	);
}
