export function formatTime(date: Date) {
	return date.toISOString();
}

type LogMeta = {
	data?: unknown;
	error?: { message: string; code?: number; stack?: string };
};

export function formatLog(message: string, opt: unknown, { logPrefix }: { logPrefix?: string }) {
	let prefix = `[${new Date().toISOString()}]`;
	if (logPrefix) {
		prefix += `${prefix ? " " : ""}[${logPrefix}]`;
	}
	prefix += " ";

	let _opt: LogMeta = {};
	if (opt) {
		let error: LogMeta["error"] = (opt as LogMeta).error;
		const maybeError = (opt as { error?: Error }).error || opt;
		if (maybeError instanceof Error) {
			error = { ...maybeError, message: maybeError.message, stack: maybeError.stack };
		}

		_opt = { ...(opt as object) };

		_opt.error = error;
	}
	return `${prefix}${message}${_opt && Object.keys(_opt).length ? ` ${JSON.stringify(_opt, null, 2)}` : ""}`;
}
