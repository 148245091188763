"use client";
import { Button } from "@/components/ui/button";
import { TypographyH1, TypographyH5 } from "@/components/ui/typography";
import { logger } from "@/log";
import { ArrowLeft } from "lucide-react";
import Link from "next/link";
import { useEffect } from "react";

export default function Error({ error }: { error: Error & { digest?: string } }) {
	useEffect(() => {
		logger.error(`error`, "Client side error", {
			error,
		});
	}, [error]);
	return (
		<div className="flex h-screen w-screen flex-col items-center justify-center gap-10">
			<div className="flex flex-col items-center justify-center gap-5">
				<TypographyH1 className="lg:text-5xl">Something went wrong!</TypographyH1>
				<TypographyH5>{`We've`} encountered an error on this page. Please reload or return back to home</TypographyH5>
			</div>
			<Button>
				<Link href={"/"} className="flex items-center gap-2 text-base">
					{" "}
					<ArrowLeft size={20} /> Back To Home
				</Link>
			</Button>
		</div>
	);
}
