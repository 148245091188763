/* eslint-disable no-console */

import { formatLog } from "./formatLog";

export const logger = {
	info: (prefix: string, message: string, opt?: unknown) => {
		console.info(formatLog(message, opt, { logPrefix: prefix }));
	},
	error: (prefix: string, message: string, opt?: unknown) => {
		console.error(formatLog(message, opt, { logPrefix: prefix }));
	},
};
